<template>
  <div>
    <MobileHome v-if="isMobile" />
    <WebHome v-else/>
    <!-- <Share :text="$t('index-seo-title')" /> -->
  </div>
</template>
<script setup>
import { useMainStore } from "@/store/index"
const mainStore = useMainStore();
const { locale, t } = useI18n();
const device = useDevice();
const link = useSeoLangLink()
// const isMobile = false;
// const title = ref(t("index-seo-title"));
const isMobile = computed(()=>device.isMobile || mainStore.isMobile)
const {
  public: { HOST },
} = useRuntimeConfig();
console.log('isMobile',isMobile.value);
useHead({
  title: t("index-seo-title"),
  meta: [
    {
      name: "description",
      content: t("index-seo-desc"),
    },
    // {
    //   name: "keywords",
    //   content: t("index-seo-keywords"),
    // },
    // {
    //   property: "og:title",
    //   content: t("index-seo-title"),
    // },
    // {
    //   property: "og:description",
    //   content: t("index-seo-desc"),
    // },
    // {
    //   property: "og:url",
    //   content: locale == "en" ? HOST : HOST + "/" + locale.value,
    // },
    // {
    //   property: "og:image",
    //   content: 'assets/images/share.webp',
    // },
  ],
  link
});

mainStore.setTheme("dark");
mainStore.setFooter(true);
onMounted(() => {});
</script>